import React from 'react'
import Navbar from '../components/navbar'
import Footer from '../components/footer'
import Container from '../components/container'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import CustomCarousel from '../components/carousel'
import { Helmet } from 'react-helmet'

export default function Article({ pageContext: { title, images, composition, weight } }) {
    const navigation = [
		{ name: 'На головну', href: '/', key: 54 },
	]

    return (
        <div>
            <Helmet>
				<meta charSet="utf-8" />
				<meta name="description" content={composition} />
				<meta name="viewport" content="width=device-width, initial-scale=1.0"/>
				<meta name="image" content={images[0].image.relativePath}/>
				<title>{title}</title>
                <meta name="theme-color" content="#53C82A"/>
                <link rel="apple-touch-icon" href="logo.png"></link>
			</Helmet>
            <div className="bg-hero-image bg-no-repeat bg-cover bg-top">
                <Container>
                    <Navbar navigation={navigation} />
                </Container>
            </div>

            <Container>
                <div className='mt-8 lg:mt-12 md:mx-8 overflow-hidden'>
                    <h1 className="text-3xl text-center tracking-tight font-bold sm:text-5xl md:text-6xl md:text-left">
                        {title}
                    </h1>
                    <div className="flex lg:flex-row flex-col items-center justify-between lg:mt-4">
                        <div className="lg:max-w-lg lg:w-1/2 w-5/6 md:mb-0 flex justify-center">
                            <div className="w-5/6">
                                <CustomCarousel slides={images.map(item => (<GatsbyImage className="w-auto h-full" image={getImage(item.image.childImageSharp.gatsbyImageData)} alt={title} />))} />
                            </div>                            
                        </div>
                        <article className="md:w-3/4 lg:mt-0 m-8 lg:w-1/2 overflow-hidden rounded-2xl custom-shadow">
                            <div className="flex flex-col px-12 py-12 text-xl">
                                <p className="mb-4">
                                    Склад: {composition}
                                </p>
                                <p className="">
                                     Об'єм/вага: {weight}
                                </p>
                            </div>
                        </article>
                    </div>

                </div>
            </Container>

            <div className="bg-green-400">
                <Container>
                    <Footer />
                </Container>
            </div>
        </div>
    )
}
